<template>
  <VueContentHolder id="divTotalLossWrapper">
    <BrandResultView
      @handleSubmit="submitResultPopUp"
      @close="closeModal"
      :key="showResultView"
      :showComponent="showResultView"
      :topTitle="resultViewItems.topTitle"
      :bottomTitle="resultViewItems.bottomTitle"
      :topValue="resultViewItems.topValue"
      :bottomValue="resultViewItems.bottomValue"
    ></BrandResultView>
    <Header :text="headerContent[0].text" :headline="headerContent[0].headline"></Header>
    <VueSpacer level="20" />
    <VueForm :bodyPadding="['0', '20']" ref="form">
      <!-- TODO: VUEINPUTLARI FOR İLE DÖN  -->
      <VueInput
        name="marlboro"
        id="marlboro"
        type="number"
        label="Marlboro"
        v-model="formModel.marlboro.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="parliament"
        id="parliament"
        type="number"
        label="Parliament"
        v-model="formModel.parliament.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="chesterfield"
        id="chesterfield"
        type="number"
        label="Chesterfield"
        v-model="formModel.chesterfield.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="lark"
        id="lark"
        type="number"
        label="Lark"
        v-model="formModel.lark.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="lm"
        id="lm"
        type="number"
        label="L&M"
        v-model="formModel.lm.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
      <VueInput
        name="muratti"
        id="muratti"
        type="number"
        label="Muratti"
        v-model="formModel.muratti.amount"
        :messageOnTop="true"
        validationRules="numeric"
        validatorName="Ürün"
      ></VueInput>
      <VueSpacer level="20" />
    </VueForm>
    <VueSpacer level="60" />
    <BrandButton
      v-if="!showResultView"
      :size="sizes.xxLarge"
      :contentAlignment="constants.flexAlignment.center"
      type="submit"
      @click="handleSubmit"
      :disabled="isDisabled"
      >HESAPLA</BrandButton
    >
  </VueContentHolder>
</template>

<script>
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import Header from '@/components/brand/Headers/Header.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';
import VueContentHolder from '@/components/shared/VueContentHolder/VueContentHolder.vue';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandResultView from '@/components/brand/BrandResultView/BrandResultView.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesRoot from '@/router/routes/RoutesRoot';
import { Learn } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'TotalLoss',
  mixins: [gtmUtils],
  components: {
    Header,
    VueForm,
    VueInput,
    BrandButton,
    VueContentHolder,
    VueSpacer,
    BrandResultView,
  },
  data() {
    return {
      isDisabled: true,
      resultViewItems: {
        topTitle: 'Ürün satamadığınız günlük müşteri sayısı',
        topValue: 0,
        bottomTitle: 'Yıllık Ciro Kaybınız',
        bottomValue: 0,
      },
      showResultView: false,
      formModel: {
        marlboro: {
          productFamily: 'ML',
          amount: '',
        },
        parliament: {
          productFamily: 'PL',
          amount: '',
        },
        lark: {
          productFamily: 'LA',
          amount: '',
        },
        lm: {
          productFamily: 'LM',
          amount: '',
        },
        muratti: {
          productFamily: 'MU',
          amount: '',
        },
        chesterfield: {
          productFamily: 'CH',
          amount: '',
        },
      },
      headerContent: [
        {
          headline: 'Yok satarak ne kadar kaybediyorsunuz?',
          text:
            'Günde kaç Philip Morris tüketicisini ürün kalmadığı için geri çevirmek zorunda kalıyorsunuz?',
        },
      ],
    };
  },
  watch: {
    formModel: {
      deep: true,
      handler() {
        let count = 0;
        Object.keys(this.formModel).forEach(key => {
          if (this.formModel[key].amount && this.formModel[key].amount.length > 0) {
            count++;
          }
          this.isDisabled = count === 0;
        });
      },
    },
  },

  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  created() {
    this.setBottomBarStatus(false);
  },
  destroyed() {
    this.setBottomBarStatus(true);
  },
  methods: {
    setBottomBarStatus(status) {
      this.$store.dispatch('app/setBottomBarStatus', status);
    },
    async handleSubmit() {
      let items = [];
      Object.values(this.formModel).map(model => {
        if (model.amount && model.amount.length && !isNaN(parseInt(model.amount))) {
          items.push({ ...model, amount: parseInt(model.amount) });
        }
      });
      Learn.getTotalLoss(items).then(res => {
        let response = res.data.Data;
        if (response) {
          this.resultViewItems.topValue = response.dailyCustomerCount;
          this.resultViewItems.bottomValue = response.annualRevenueLoss;
          this.showResultView = true;

          this.pushDataLayerEvent('learn', {
            event: 'yok_kayip',
            ...Object.fromEntries(
              Object.entries(this.formModel).map(([key, val]) => [key, Number(val.amount)]),
            ),
            daily_notsales_customer: response.dailyCustomerCount,
            yearly_revenue_lost: response.annualRevenueLoss,
          });
        }
      });

      if (this.showResultView) {
        document.documentElement.style.overflow = 'hidden';
      }
    },
    closeModal() {
      this.showResultView = false;
      if (!this.showResultView) {
        document.documentElement.style.overflow = 'auto';
      }
    },
    submitResultPopUp() {
      this.$router.push(`${RoutesRoot.Secure.path}/${RoutesSecure.ManageMyBusiness.path}`);
    },
  },
};
</script>

<style lang="scss"></style>
