<template>
  <div class="header-wrapper">
    <VueHeadline weightLevel="3" level="3" color="grey-40" class="headline">
      {{ headline }}</VueHeadline
    >
    <VueSpacer level="20"></VueSpacer>

    <VueText sizeLevel="4" weightLevel="2" class="text" color="grey-30">{{ text }}</VueText>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import VueSpacer from '@/components/shared/VueSpacer/VueSpacer.vue';

export default {
  name: 'Header',
  components: { VueText, VueHeadline, VueSpacer },
  props: {
    text: {
      type: String,
    },
    headline: {
      type: String,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped lang="scss">
.header-wrapper {
  border-bottom: 1px solid palette-color-level('grey', '20');
  background-color: palette-color-level('grey', '10');
  padding: palette-space-level('15');
}

.headline {
  width: 100%;
}

.text {
  line-height: 1.57;
  padding-bottom: palette-space-level('20');
}
</style>
